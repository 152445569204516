body {
  padding-bottom: 25px;
}

.styled-table {
  border-collapse: collapse;
  margin: 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #787e80;
  color: #fff;
  text-align: left;
}

.styled-table th {
  padding: 12px 15px;
  text-align: center;
  white-space: nowrap;
}

.styled-table td {
  padding: 12px 15px;
  text-align: center;
}

.styled-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tr.active:nth-of-type(even) {
  background-color: #ccfbcc;
}

.styled-table tr.active:nth-of-type(odd) {
  background-color: #aff7af;
}

.styled-table tr.active:nth-of-type(odd):hover {
  background-color: #aff7af;
}

.styled-table tbody tr:nth-of-type(even):hover {
  filter: brightness(0.97);
}

.styled-table tbody tr:nth-of-type(odd):hover {
  filter: brightness(0.97);
  background-color: #00000008;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.active_deployed {
  color: black;
}

.active_deployed a {
  color: black;
  font-weight: bold;
}

.active_not_deployed {
  color: grey;
}

.active_not_deployed a {
  color: grey;
}

$base-color-development: #ddd;
$base-color-production: #3dff00;
$base-color-staging: #429dfd;

.navbar > a.navbar-brand.development {
  color: $base-color-development;
}

.navbar > a.navbar-brand.development:hover {
  color: #fff;
}

.navbar > a.navbar-brand.production {
  color: $base-color-production;
}

.navbar > a.navbar-brand.production:hover {
  color: #a7ff00;
}

.navbar > a.navbar-brand.staging {
  color: $base-color-staging;
}

.navbar > a.navbar-brand.staging:hover {
  color: #7cbbfd;
}

.footer-copyright.development {
  color: $base-color-development;
}

.footer-copyright.production {
  color: $base-color-production;
}

.footer-copyright.staging {
  color: $base-color-staging;
}

a.nav-link {
  color: #bbb !important;
  padding: 4px 0 0 0;
}

a.nav-link:hover {
  color: white !important;
}

a.nav-link.active {
  color: white !important;
}

/* Taken from https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.grow-wrap {
  display: grid;
}

.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
  border: 1px solid black;
  padding: 0.5rem;
  grid-area: 1 / 1 / 2 / 2;
  margin-bottom: 5px;
}

.alert {
  margin-bottom: 0;
}

.colored-box {
  font-weight: bold;
  padding: 5px;
}

.initial-test {
  background-color: #acdfff;
}

.outstanding_payment {
  color: red;
  font-weight: bold;
}

.test_task_payment {
  color: #ffaeae;
}

.sort_link {
  cursor: pointer;
}

.number-circle {
  border-radius: 50%;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  font-weight: bold;
}

.number-circle.batch {
  background: #fff;
  border: 1px solid #666;
  color: #666;
}

.number-circle.batches {
  border: 1px solid #666;
  background: #666;
  color: #fff;
}

// Superuser
body.superuser {
  background-color: #e8e7e8;
}

.styled-table.superuser tbody tr:last-of-type {
  border-bottom: 2px solid #b1b1b1;
}

// Group
body.group_manager {
  background-color: #e5f1ff;
}

#profile.group_manager {
  background-color: #94c1f5;
}

.styled-table.group_manager tbody tr:last-of-type {
  border-bottom: 2px solid #7eb1ea;
}

// Worker
body.worker {
  background-color: #ffffef;
}

#profile.worker {
  background-color: #babbab;
}

.styled-table.worker tbody tr:last-of-type {
  border-bottom: 2px solid #00ce4c;
}

.count {
  color: #212529;
  font-size: larger;
  font-weight: bold;
}

.count:hover {
  color: black;
}

.log_entry.updated {
  color: orange;
}

.log_entry.created {
  color: limegreen;
}

.log_entry.deleted {
  color: red;
}

// Placeholder
.box-placeholder {
  display: inline-block;
  max-width: 162px;
  vertical-align: top;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;

  .text {
    display: inline-block;
    background-color: #444;
    height: 12px;
    border-radius: 100px;
    margin: 5px 0;
    min-width: 100px;
    opacity: 0.1;
    animation: fading 1.5s infinite;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.link {
      background-color: var(--blue);
      opacity: 0.4;
    }

    &.line {
      width: 100%;
    }

    &.category {
      width: 100px;
      margin-bottom: 10px;
    }
  }
}

.bonus:hover {
  text-decoration: underline;
}

#logout:hover {
  text-decoration: underline;
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}

// Highlight log entry
.target-highlight {
  -webkit-animation: target-fade 5s 1;
  -moz-animation: target-fade 5s 1;
}

@-webkit-keyframes target-fade {
  0% { color: rgba(255, 255, 1, 1); }
  100% { color: rgba(255, 255, 255, 1); }
}

@-moz-keyframes target-fade {
  0% { color: rgba(255, 255, 0, 1); }
  100% { color: rgba(255, 255, 255, 1); }
}

.info-box {
  color: black;
  font-weight: bold;
  float: left;
  padding: 7px 14px 2px 14px;
  margin-left: 15px;
  border-radius: 18px;
  border-bottom: 1px solid #64937b;
  letter-spacing: 0.5px;
  background-image: linear-gradient(#d9eddb, #90b983);
  box-shadow: 0 3px 90px rgb(182 203 183 / 30%);
  box-sizing: border-box;
}

.show_all_toggle {
  cursor: pointer;
  color: black;
  font-weight: bold;
}

.show_all_toggle:hover {
  color: black;
}

.hide_select_toggle {
  cursor: pointer;
  color: black;
  font-weight: bold;
}

.hide_select_toggle:hover {
  color: black;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #3dff00;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.form-group {
  font-weight: bold;
}

#profile-dropdown {
  position: fixed;
  right: 0;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-200, #bfbfc3);
  border-bottom-left-radius: 0.25rem;
  box-shadow: 0 2px 8px var(--gl-shadow-color-default), 0 0 2px var(--gl-shadow-color-default);
  min-width: 15.5rem;
  max-width: 28.5rem;
  z-index: 1000;
}
